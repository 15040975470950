<template>
	<span>
		{{ mwutils.prettyPrint(item[column.value], "TITLE") }}
	</span>
</template>

<script>
export default {
	name: "StringCell",
	props: ["item", "column"],
};
</script>
